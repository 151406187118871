import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { TechnologyComponent } from './technology/technology.component';
import { CareersComponent } from './careers/careers.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { CarrierformComponent } from './carrierform/carrierform.component';
import { FooterComponent } from './footer/footer.component';
import { SoftspacetechService } from './softspacetech.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoPopupComponent } from './info-popup/info-popup.component';
import {  NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    TechnologyComponent,
    CareersComponent,
    ContactComponent,
    ServicesComponent,
    CarrierformComponent,
    FooterComponent,
    InfoPopupComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [SoftspacetechService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [CarrierformComponent, InfoPopupComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
