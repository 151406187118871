<section id="up" class="pos-rel bg-img-cover">
  <div class="bg-video-wrap">
    <!--<video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/images/video/banner-bg-video.mp4" type="video/mp4" />
    </video>-->
    <div class="overlay">
    </div>
    <h1 class="linear-wipe">
      Contact
    </h1>
  </div>
</section><!-- page head end -->
<form method="post" name="formobrsv" id="send_form" class="flex-container" [formGroup]="contactForm" autocomplete="disabled">
  <div class="container pos-rel padding-top-bottom-120 enq-form">
    <div class="container">
      <!-- column start -->
      <div class="flex-container light-bg">
        <div class="twelve-columns">
          <div class="common-heading text-l">
            <h2 class="mt0">Write to us</h2>
          </div>
          <h3 class="headline-xxxs text-color-black">
            <span class="anim-slide js-scrollanim js-scrollanim-active">Contact Now</span>
          </h3>
        </div>
        <div class="six-columns  padding-top-60">
          <div class="form-block">

            <!-- column start -->
            <div class="twelve-columns">
              <div class="">
                <input type="text" name="Name" placeholder="Enter your name" formControlName="Name" class="form-input js-pointer-small">
              </div>
            </div><!-- column end -->
            <!-- column start -->
            <div class="twelve-columns">
              <div class="">
                <input type="text" name="Email" placeholder="Email" formControlName="Email" class="form-input js-pointer-small">
              </div>
            </div><!-- column end -->
            <!-- column start -->
            <div class="twelve-columns">
              <div class="">
                <input type="text" name="Mobile" placeholder="Mobile" formControlName="Mobile" class="form-input js-pointer-small">
              </div>
            </div><!-- column end -->
            <!-- column start -->
            <div class="twelve-columns">
              <textarea name="message" placeholder="Enter your message" formControlName="Message" class="form-input js-pointer-small"></textarea>
            </div><!-- column end -->
            <!-- column start -->
            <div class="twelve-columns text-center padding-top-90">
              <button type="button" class="button-contact" (click)="postEnquiry()">Contact</button>
             
            </div><!-- column end -->
          </div>
        </div>
        <div class="six-columns  padding-top-60">
          <!--<img src="../../assets/images/illustrations/mail.png" alt="logo-image" />-->
          <div class="contact-card">
            <div class="info-card v-center">
              <span><i class="fas fa-phone"></i> Phone</span>
              <div class="info-body">
                <p>Assistance hours: Monday – Friday, 9 am to 5 pm</p>
                <a>(+971) 4 297 1111 (UAE)</a><br><br>
                <a *ngIf="coutryCode=='IN'">(+91) 0484 3533407 (India)</a>
              </div>
            </div>
          </div>
          <div class="contact-card">
            <div class="info-card v-center">
              <span><i class="fas fa-envelope"></i> Email</span>
              <div class="info-body">
                <p>Our support team will get back in 24hrs during standard business hours.</p>
                <a href="">info@softspace.ae</a>
              </div>
            </div>
          </div>
          <div class="contact-card">
            <div class="info-card v-center">
              <span><i class="fas fa-map-marker-alt"></i>Address</span>
              <div class="info-body">
                <h6 >UAE Office</h6>
                <p>
                  Softspace Technologies LLC<br>
                  Office #: 604 & 605, 6th Floor, <br>
                  Century 21 Building
                  Abu Baker Al Siddique Road<br>
                  Deira, Dubai, UAE   <br>
                  P.O Box: 11332
                </p>
                <h6 *ngIf="coutryCode=='IN'">India Office</h6>
                <p *ngIf="coutryCode=='IN'">
                  Softspace Infotech Pvt. Ltd<br>
                  Office #11, 8th Floor, Vismaya Infopark,<br>
                  Infopark Campus, Phase 1, Kochi, Kakkanad,<br>
                  Kerala, India
                </p>
                <a href="tel:+10000000000"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
