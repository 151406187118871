<div class="carrier_form_wrap">
  <form method="post" name="formobrsv" class="flex-container" [formGroup]="carreerForms" (ngSubmit)="SubmitCarrers()" autocomplete="disabled">
    <div class="carrier-form">
      <button title="Close (Esc)" type="button" class="mfp-close modal-close-button" mat-dialog-close>×</button>
      <h2 class="">Think you are a fit? Apply Now.</h2>
      <div class="flex-container padding-top-30">
        <div class="twelve-columns  padding-top-60">
          <div class="form-block">

            <!-- column start -->
            <div class="six-columns">
              <div class="">
                <input type="text" name="name" formControlName="Name" placeholder="Enter your name" required="" class="form-input-carrier js-pointer-small">
              </div>
            </div><!-- column end -->
            <!-- column start -->
            <div class="six-columns">
              <div class="">
                <input type="text" name="Mobile" formControlName="Mobile" placeholder="Mobile" required="" class="form-input-carrier js-pointer-small">
              </div>
            </div><!-- column end -->
            <!-- column start -->
            <div class="six-columns">
              <div class="">
                <input type="text" name="Email" formControlName="Email" placeholder="Email" required="" class="form-input-carrier js-pointer-small">
              </div>
            </div><!-- column end -->
            <!-- column start -->
            <div class="six-columns">
              <div class="">
                <input type="text" name="LinkedinUrl" formControlName="LinkedinUrl" placeholder="Linkedin URL" required="" class="form-input-carrier js-pointer-small">
              </div>
            </div><!-- column end -->
            <div class="six-columns">
              <div class="form-group qualification-wrapper">
                <!--<input type="text" name="first_name" placeholder="Qualification" id="" required="" class="form-input-carrier js-pointer-small">-->
                <select class="form-control select-qualification" formControlName="Qualification">
                  <option value="0" class="sss">Select Qualification</option>
                  <option *ngFor="let itm of qualification" [value]="itm.QualificationID">{{itm.QualificationDesc}}</option>
                </select>
              </div>
            </div><!-- column end -->
            <div class="six-columns">
              <div class="">
                <input type="text"  placeholder="Other Qualification" required="" class="form-input-carrier js-pointer-small">
              </div>
            </div><!-- column end -->
            <!-- column start -->
            <div class="six-columns">
              <div class="">
                <input type="text" name="Place" formControlName="Place" placeholder="Place" required="" class="form-input-carrier js-pointer-small">
              </div>
            </div><!-- column end -->

          </div>
        </div>
        <div class="twelve-columns">
          <textarea name="message" placeholder="Tell us a little about yourself." formControlName="Descriptions" required="" class="form-textare-input js-pointer-small"></textarea>
        </div>
        <!-- column start -->
        <div class="twelve-columns">
          <div class="input_file_upload">
            <p>Upload Resume</p>
            <span *ngIf="carreerForms.value.file!=''" class="resume-uploaded">{{dispName}} &nbsp;<i class="fas fa-times"></i></span>
            <input type="file" name="file" placeholder="Resume" formControlName="file" required="" (change)="onFileChange($event)" class="form-input-carrier-file js-pointer-small">
            <label>Choose File</label>
            
            <!--<div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
    <div *ngIf="f.file.errors.required">File is required.</div>
  </div>-->
          </div>
        </div><!-- column end -->
        <div class="twelve-columns">
          <!-- column start -->
          <div class="twelve-columns text-center padding-top-30">

            <button type="button" class="button-contact" (click)="SubmitCarrers()">Apply Now</button>
            <span class="alert-danger" *ngIf="validationMess!=''">{{validationMess}}</span>
          </div><!-- column end -->
        </div>
      </div>
    </div>
  </form>
</div>
