import { Component, OnInit } from '@angular/core';
import { SoftspacetechService } from '../softspacetech.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  constructor(public service: SoftspacetechService) { }
  coutryCode!: any;
  ngOnInit(): void {
    const code = localStorage.getItem('CountryCode')?.toString();
    if (code == null || code == '') {
      this.service.getIpAddress().subscribe((data: any) => {
        this.service.getGEOLocation(data.ip).subscribe((x: any) => {
         
          localStorage.setItem('CountryCode', x.country_code2);
          this.coutryCode = x.country_code2;
        });
      });
    }
    else {
      this.coutryCode = code?.toString();
    }
    
  }

}
