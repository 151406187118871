<section id="up" class="pos-rel bg-img-cover">
  <div class="bg-video-wrap for_web">
    <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/images/video/banner-bg-video.mp4" type="video/mp4" />
    </video>
    <div class="overlay">
    </div>
    <!--<h1 class="linear-wipe">
      Welcome To Softspace Technologies...
    </h1>-->
  </div>
  <div class="bg-video-wrap for_mob" >
    <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/images/video/banner-bg-video-mobile.mp4" type="video/mp4"  />
    </video>
    <div class="overlay">
    </div>
   
  </div>
  
</section><!-- page head end -->
<section class="content-details">
  <div class="container after-preloader-anim">
    <div class="container padding-top-120">
      <h2 class="headline-xxs text-color-black hidden-box">
        <span class="anim-slide js-scrollanim js-scrollanim-active" *ngIf="coutryCode!='IN'">Softspace Technologies LLC</span>
        <span class="anim-slide js-scrollanim js-scrollanim-active" *ngIf="coutryCode=='IN'">Softspace Infotech Pvt Ltd</span>
      </h2>
      <!-- flex-container start -->
      <div class="flex-container padding-top-30">
        <!-- column start -->
        <div class="five-columns column-100-100 padding-top-60">
          <div class="column-r-margin-40-999">
            <img src="assets/images/backgrounds/left-img-about.jpg" alt="about-image" /> 
          </div>
        </div><!-- column end -->
        <!-- column start -->
        <div class="seven-columns column-100-100 padding-top-60">
          <p class="body-text-s text-color-black anim-text-reveal js-scrollanim js-scrollanim-active">
            <span *ngIf="coutryCode!='IN'">Softspace Technologies LLC</span><span *ngIf="coutryCode=='IN'">Softspace Infotech Pvt Ltd</span> create innovative and precise
            applications for future. We believe , in the future most of the business will rely on peace of software that can make their work easier and faster.
            So here we are creating web and mobile applications for organizations. We transform your business by building innovative digital applications. Our
            way of development is to collect Information about the problems or targets of our client and nature of work, to add our Innovation and thereby
            providing Solutions in a simple and productive manner.
          </p>
          <p class="body-text-s text-color-black anim-text-reveal js-scrollanim js-scrollanim-active">
            Our business philosophy is to assure the highest quality product with total client satisfaction and timely delivery of solutions. Our emphasis is
            on offering a high degree of product user friendliness through a positive, creative, and dedicated team. We believe that the future is digital.
            We are passionate about creating the technologies with the new paces of Artificial Intelligence and Internet of Things.We also believe that without
            embracing new technology, businesses will strive in chaos.
          </p>
        </div><!-- column end -->
      </div><!-- flex-container end -->
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <div class="sec-head">
          <h6 class="anim-slide tr-delay-01 d-block">what we do</h6>
          <h3 class="color-font anim-slide tr-delay-01">We Create Technology For Future</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="flex-container padding-top-30">
        <!-- column start -->
        <div class="four-columns column-100-100 anim-slide tr-delay-01 card-block-3d padding-top-60">
          <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active ser-boxes">
            <span class="subhead-xxl text-color-red d-block hidden-box">
              <span class="ic_block">
                <img src="assets/images/services/crm.png" alt="logo-image" />
              </span>
            </span>
            <h3 class="headline-xxxs text-color-black margin-top-30 hidden-box">
              <span class="anim-slide tr-delay-01">Ai Innovations</span>
            </h3>
            <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-delay-02">
              We assess your technological maturity and readiness for AI-driven automation and follow you through all the stages of adopting artificial
              intelligence in your business operations, from analyzing your use case to developing AI-driven software and ensuring its positive user buy-in.
             
            </p>
          </div>
        </div><!-- column end -->
        <!-- column start -->
        <div class="four-columns column-100-100 anim-slide tr-delay-02 padding-top-60">
          <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active ser-boxes">
            <span class="subhead-xxl text-color-red d-block hidden-box">
              <span class="ic_block">
                <img src="assets/images/services/recruitment.png" alt="logo-image" />
              </span>
            </span>
            <h3 class="headline-xxxs text-color-black margin-top-30 hidden-box">
              <span class="anim-slide tr-delay-01">Business Automation</span>
            </h3>
            <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-delay-02">
              <span *ngIf="coutryCode!='IN'">Softspace Technologies LLC</span><span *ngIf="coutryCode=='IN'">Softspace Infotech Pvt Ltd</span> develops and improves enterprise software that helps businesses of all sizes to enable automation, analytics, and collaboration.
              We know exactly how to leverage the cutting-edge technology solutions to generate more revenue for your and create greater value for your customers.
            </p>
          </div>
        </div><!-- column end -->
        <!-- column start -->
        <div class="four-columns column-100-100 anim-slide tr-delay-03 padding-top-60">
          <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active ser-boxes">
            <span class="subhead-xxl text-color-red d-block hidden-box">
              <span class="ic_block">
                <img src="assets/images/services/applicants.png" alt="logo-image" />
              </span>
            </span>
            <h3 class="headline-xxxs text-color-black margin-top-30 hidden-box">
              <span class="anim-slide tr-delay-01">Digital Transformation</span>
            </h3>
            <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-delay-02">
              <span *ngIf="coutryCode!='IN'">Softspace Technologies LLC</span><span *ngIf="coutryCode=='IN'">Softspace Infotech Pvt Ltd</span> helps companies make the promise of digital transformation a reality.  We need a deep
              understanding of the risks and opportunities presented by new emerging technologies and think creatively about how you can use these technologies
              to improve business performance.
            </p>
          </div>
        </div><!-- column end -->
        <!-- column start -->
        <div class="four-columns column-100-100 padding-top-60">
          <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active ser-boxes">
            <span class="subhead-xxl text-color-red d-block hidden-box">
              <span class="ic_block">
                <img src="assets/images/services/user-interface.png" alt="logo-image" />
              </span>
            </span>
            <h3 class="headline-xxxs text-color-black margin-top-30 hidden-box">
              <span class="anim-slide tr-delay-01">Mobile App Development</span>
            </h3>
            <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-delay-02">
              Developing mobile apps is both a way of keeping up with the times and of offering your current and
              potential customers easy access to the information and services they seek, leading eventually to the
              growth of your business. Mobile app development is the best way to increase your online visibility than
              other marketing methods.
            </p>
          </div>
        </div><!-- column end -->
        <!-- column start -->
        <div class="four-columns column-100-100 padding-top-60">
          <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active ser-boxes">
            <span class="subhead-xxl text-color-red d-block hidden-box">
              <span class="ic_block">
                <img src="assets/images/services/web-application.png" alt="logo-image" />
              </span>
            </span>
            <h3 class="headline-xxxs text-color-black margin-top-30 hidden-box">
              <span class="anim-slide tr-delay-01">Web Application</span>
            </h3>
            <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-delay-02">
              Regardless of how complex or even small your needs are, our dedicated in-house team of web developers
              and project managers will ensure that the job gets done with the highest level of professionalism and
              most cost affordably. Our expert in-house team uses cutting edge technologies to build custom web
              applications that are stunning, robust, secure.
            </p>
          </div>
        </div><!-- column end -->
        <!-- column start -->
        <div class="four-columns column-100-100 padding-top-60">
          <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active ser-boxes">
            <span class="subhead-xxl text-color-red d-block hidden-box">
              <span class="ic_block">
                <img src="assets/images/services/ui-design.png" alt="logo-image" />
              </span>
            </span>
            <h3 class="headline-xxxs text-color-black margin-top-30 hidden-box">
              <span class="anim-slide tr-delay-01">UI/UX Designing</span>
            </h3>
            <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-delay-02">
              UX and UI are the two most important aspects of any design process. One drives the other and as they
              say UI is incomplete without UX and vice-versa. We offer beautiful interaction processes that are
              driven by the most modern technologies to offer a great interactive experience for your users
            </p>
            <br>
          </div>
        </div><!-- column end -->
      </div>
    </div>
  </div>
  <div class="container flex-container padding-top-60 padding-bottom-120">
    <!-- column start -->
    <div class="five-columns column-100-100 padding-top-60">
      <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active">
        <h2 class="headline-m text-color-black hidden-box">
          <span class="anim-slide">Web & Mobile App Development</span>
        </h2>
        <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-dela02">
          We create bespoke digital products that solve problems, drive engagement, and introduce new trends.
        </p>
        <div class="margin-top-30 anim-fade tr-delay-02">
          <a routerLink="/Services" class="skew-btn js-pointer-large">
            <span class="skew-btn__box">
              <span class="skew-btn__content text-color-black">Know More</span>
              <span class="skew-btn__arrow black"></span>
            </span>
          </a>
        </div>
      </div>
    </div><!-- column end -->
    <!-- column start -->
    <div class="seven-columns column-100-100 padding-top-60">
      <div class="js-scrollanim js-scrollanim-active right-colum-image-wrapper">
        <img src="assets/images/illustrations/left-panel image.png" alt="left-image" />
      </div>
    </div><!-- column end -->
  </div>
  <div class="flex-container">
  </div>
  <div class="flex-container banner-content">
    <div class="container flex-container padding-top-40">
      <!-- column start -->
      <div class="four-columns column-100-100">
        <div class="">
          <div class="tech-logo">
            <img src="assets/images/illustrations/img9.png" alt="logo-image" />
          </div>
        </div>
      </div><!-- column end -->
      <!-- column start -->
      <div class="eight-columns column-100-100 padding-top-30">
        <h2 class="title m-b15 title-s">Using of advanced opensource and cross platform tools and technologies for the development guarantees an increase in productivity and efficiency with minimal effort. Cross-platform frameworks seek to generate an app that reaches out to as many followers of a brand as possible by covering a wide number of end devices during the programming and creation process. </h2>
        <!--<p class="body-text-s anim-text-reveal js-scrollanim js-scrollanim-active margin-top-20">
          Our business philosophy is to assure the highest quality product with total client satisfaction and timely delivery of solutions. Our emphasis is on offering a high degree of product user friendliness through a positive, creative, and dedicated team.
        </p>-->
      </div><!-- column end -->
    </div>
  </div>

  <div class="js-infinite-slider hidden-box padding-top-120 padding-bottom-120 swiper-container-initialized swiper-container-horizontal">
    <!-- swiper-wrapper start -->
    <div class="swiper-wrapper" style="transform: translate3d(-2243.5px, 0px, 0px); transition-duration: 5000ms;">
      <div class="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="3" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/guldoyjan.png" alt="clients logo">
      </div>
    <div class="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="4" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/jorgo.png" alt="clients logo">
      </div><div class="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="5" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/judoboy.png" alt="clients logo">
      </div><div class="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="6" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/kizil-jindi.png" alt="clients logo">
      </div><div class="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="7" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/meyman.png" alt="clients logo">
      </div><div class="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="8" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/olchobay.png" alt="clients logo">
      </div>
      <!-- swiper-slide start -->
      <div class="swiper-slide text-center swiper-slide-prev" data-swiper-slide-index="0" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/chayhana.png" alt="clients logo">
      </div><!-- swiper-slide end -->
      <!-- swiper-slide start -->
      <div class="swiper-slide text-center swiper-slide-active" data-swiper-slide-index="1" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/dongolok.png" alt="clients logo">
      </div><!-- swiper-slide end -->
      <!-- swiper-slide start -->
      <div class="swiper-slide text-center swiper-slide-next" data-swiper-slide-index="2" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/eptep-septep.png" alt="clients logo">
      </div><!-- swiper-slide end -->
      <!-- swiper-slide start -->
      <div class="swiper-slide text-center" data-swiper-slide-index="3" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/guldoyjan.png" alt="clients logo">
      </div><!-- swiper-slide end -->
      <!-- swiper-slide start -->
      <div class="swiper-slide text-center" data-swiper-slide-index="4" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/jorgo.png" alt="clients logo">
      </div><!-- swiper-slide end -->
      <!-- swiper-slide start -->
      <div class="swiper-slide text-center" data-swiper-slide-index="5" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/judoboy.png" alt="clients logo">
      </div><!-- swiper-slide end -->
      <!-- swiper-slide start -->
      <div class="swiper-slide text-center" data-swiper-slide-index="6" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/kizil-jindi.png" alt="clients logo">
      </div><!-- swiper-slide end -->
      <!-- swiper-slide start -->
      <div class="swiper-slide text-center" data-swiper-slide-index="7" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/meyman.png" alt="clients logo">
      </div><!-- swiper-slide end -->
      <!-- swiper-slide start -->
      <div class="swiper-slide text-center" data-swiper-slide-index="8" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/olchobay.png" alt="clients logo">
      </div><!-- swiper-slide end -->
      <div class="swiper-slide text-center swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/chayhana.png" alt="clients logo">
      </div><div class="swiper-slide text-center swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/dongolok.png" alt="clients logo">
      </div><div class="swiper-slide text-center swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/eptep-septep.png" alt="clients logo">
      </div><div class="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="3" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/guldoyjan.png" alt="clients logo">
      </div><div class="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="4" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/jorgo.png" alt="clients logo">
      </div><div class="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="5" style="width: 300.5px; margin-right: 20px;">
        <img class="client-logo" src="assets/images/clients/judoboy.png" alt="clients logo">
      </div>
    </div><!-- swiper-wrapper end -->
    <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
  </div>
</section>


