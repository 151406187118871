import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { NavigationEnd, Router } from '@angular/router';
import { CarrierformComponent, dialogModal } from '../carrierform/carrierform.component';
import { SoftspacetechService } from '../softspacetech.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  constructor(public dialog: MatDialog, private router: Router) { }
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;
  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.accordion?.openAll();
  }
  applypost() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    const obj: dialogModal = { Title: 'Application', Message: '' };
    const docViewModal = this.dialog.open(CarrierformComponent, {
      data: obj
    });
  }
}
