
<footer class="pos-rel bg-img-cover padding-top-40 padding-bottom-40 footer-section" style="background-color:#1F1F1F; z-index: 1; bottom: 0px; ">
  <!-- bg-overlay -->
  <div class="bg-overlay-black"></div>
  <!-- pos-rel start -->
  <div class="pos-rel">
    <!-- container start -->
    <div class="container">
      <!-- footer-logo start -->
      <!-- flex-container start -->
      <div class="flex-container flex-align-center">
        <!-- column start -->
        <div class="eight-columns">
          <h4 class="column-l-r-margin-10 headline-xs footer-title">
            <span class="text-color-White">Technology </span>
            <span class="text-color-#dadada" style="color: #896eff;">Creates</span> <br>
            Space
            <span class="text-color-White">For </span>
            <span class="text-color-#dadada" style="color: #896eff;">Future</span>
          </h4>
        </div><!-- column end -->
        <!-- column start -->
        <div class="four-columns footer-nav-mobile-padding">
          <ul class="column-l-r-margin-10 footer-nav-list js-footer-hover-box">
            <li>
              <a routerLink="/" class="footer-nav__btn js-pointer-small js-footer-hover-link">Home</a>
            </li>
            <li>
              <a routerLink="/About" class="footer-nav__btn js-pointer-small js-footer-hover-link">About</a>
            </li>
            <li>
              <a routerLink="/Services" class="footer-nav__btn js-pointer-small js-footer-hover-link">Services</a>
            </li>
            <li>
              <a routerLink="/Technology" class="footer-nav__btn js-pointer-small js-footer-hover-link">Technologies</a>
            </li>
            <li>
              <a routerLink="/Careers" class="footer-nav__btn js-pointer-small js-footer-hover-link">Careers</a>
            </li>
            <li>
              <a routerLink="/ContactUs" class="footer-nav__btn js-pointer-small js-footer-hover-link">Contact</a>
            </li>
          </ul>
        </div><!-- column end -->
      </div><!-- flex-container end -->
      <!-- flex-container start -->
      <div class="flex-container flex-justify-center padding-top-30">
        <!-- column start -->
        <div class="four-columns column-50-100 padding-top-60 footer-email">
          <div class="column-l-r-margin-10">
            <div class="headline-xxxxs">Email</div>
            <div class="margin-top-10">
              <a class="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">info@softspace.ae</a><br>
            </div>
          </div>
        </div><!-- column end -->
        <!-- column start -->
        <div class="four-columns column-50-100 padding-top-60 footer-address">
          <div class="column-l-r-margin-10">
            <div class="headline-xxxxs">Address</div>
            <div class="margin-top-10" *ngIf="coutryCode!='IN'">
              <a class="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">
                Softspace Technologies LLC
                Office 604 & 605, 6th Floor,
                Century 21 Building Abu Baker Al Siddique Road
                Deira, Dubai, UAE
                P.O Box: 11332
              </a>
            </div>
            <div class="margin-top-10" *ngIf="coutryCode=='IN'">
              <a class="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">
                Softspace Infotech Pvt Ltd,<br>
                Office #11, 8th Floor, Vismaya Infopark, <br>
                Infopark Campus, Phase 1, Kochi, Kakkanad,<br>
                Kerala, India
              </a>
            </div>
          </div>
        </div><!-- column end -->
        <!-- column start -->
        <div class="four-columns column-50-100 padding-top-60 footer-phone">
          <div class="column-l-r-margin-10">
            <div class="headline-xxxxs">Phone</div>
            <div class="margin-top-10" *ngIf="coutryCode!='IN'">
              <a href="#" class="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">
                (+971) 4 297 1111 (UAE)
              </a><br>
              <!--<a href="#" class="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">+23 8 9635 7335</a>-->
            </div>
           
            <div class="margin-top-10" *ngIf="coutryCode=='IN'">
              <a href="#" class="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">
                (+91) 0484 3533407 (India)
              </a><br>
              <!--<a href="#" class="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small">+23 8 9635 7335</a>-->
            </div>
          </div>
        </div><!-- column end -->
      </div><!-- flex-container end -->
      <!-- footer-social start -->
      <!--<ul class="footer-social-desktop-hidden list list_center padding-top-90">
        <li class="list__item">
          <a href="#" class="flip-btn js-pointer-small" data-text="Facebook">Facebook</a>
        </li>
        <li class="list__item">
          <a href="#" class="flip-btn js-pointer-small" data-text="Dribbble">Dribbble</a>
        </li>
        <li class="list__item">
          <a href="#" class="flip-btn js-pointer-small" data-text="Instagram">Instagram</a>
        </li>
        <li class="list__item">
          <a href="#" class="flip-btn js-pointer-small" data-text="Youtube">Youtube</a>
        </li>
        <li class="list__item">
          <a href="#" class="flip-btn js-pointer-small" data-text="Behance">Behance</a>
        </li>
        <li class="list__item">
          <a href="#" class="flip-btn js-pointer-small" data-text="Twitter">Twitter</a>
        </li>
      </ul>-->
      <!-- footer-copyright start -->
      <div class="footer-copyright text-center pos-abs" style="padding-top:13px;">
        <p class="copyright-style">
          Copyright © 2020 - 2021 <span *ngIf="coutryCode!='IN'">Softspace Technologies LLC</span><span *ngIf="coutryCode=='IN'">Softspace Infotech Pvt Ltd</span> <a routerLink="/" class="text-weight-700 js-pointer-small">| All Rights Reserved</a>
        </p>
      </div><!-- footer-copyright end -->
    </div><!-- container end -->
  </div><!-- pos-rel end -->
</footer>
