import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  exports: [
    MatDialogModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule
  ],
  declarations: [],
  imports: [
    CommonModule,
    MatDialogModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
  ]
})
export class MaterialModule { }
