import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { error } from 'protractor';
import { InfoPopupComponent } from '../info-popup/info-popup.component';
import { SoftspacetechService } from '../softspacetech.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  message: string = '';
  coutryCode!: any;
  constructor(public service: SoftspacetechService, private fb: FormBuilder, private router: Router, public dialog: MatDialog, private spinner: NgxSpinnerService) { }
  contactForm = this.fb.group({
    Name: [''],
    Email: [''],
    Mobile: [''],
    Message:['']

  })
  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    const code = localStorage.getItem('CountryCode')?.toString();
    if (code == null || code == '') {
      this.service.getIpAddress().subscribe((data: any) => {
        this.service.getGEOLocation(data.ip).subscribe((x: any) => {

          localStorage.setItem('CountryCode', x.country_code2);
          this.coutryCode = x.country_code2;
        });
      });
    }
    else {
      this.coutryCode = code?.toString();
    }
    console.log(this.coutryCode);
  }

  postEnquiry() {
    this.spinner.show();
    const enqObj: EnquiryModel = {
      CommunicationId: 0,
      Name: this.contactForm.get('Name')?.value,
      Email: this.contactForm.get('Email')?.value,
      Mobile: this.contactForm.get('Mobile')?.value,
      Message: this.contactForm.get('Message')?.value,
      MailContent: ''
    };
    this.service.PostEnquiry(enqObj).subscribe((data: any) => {
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });
      this.spinner.hide();
      this.message = data.Message;
      const dialogRef = this.dialog.open(InfoPopupComponent, {
        data: { Title: 'Contact', Message: this.message}
      });
      dialogRef.afterClosed().subscribe(e => {
        if (data.Status) {
          this.contactForm.get('Name')?.setValue('');
          this.contactForm.get('Email')?.setValue('');
          this.contactForm.get('Mobile')?.setValue('');
          this.contactForm.get('Message')?.setValue('');
        }
      }, (error) => {
        this.spinner.hide();
      });
     
    });
    //const dialogRef = this.dialog.open(InfoPopupComponent, {
    //  data: { Title: 'Contact', Message:'Testing content' }
    //});
  }
}
export interface EnquiryModel {
  CommunicationId: number;
  Name: string;
  Email: string;
  Mobile: string;
  Message: string;
  MailContent: string;
}
