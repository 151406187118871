import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { InfoPopupComponent } from '../info-popup/info-popup.component';
import { SoftspacetechService } from '../softspacetech.service';

@Component({
  selector: 'app-carrierform',
  templateUrl: './carrierform.component.html',
  styleUrls: ['./carrierform.component.scss']
})
export class CarrierformComponent implements OnInit {
  fname: any;
  dispName: any;
  validationMess: string='';
  constructor(public dialogRef: MatDialogRef<CarrierformComponent>, @Inject(MAT_DIALOG_DATA) public data: dialogModal, public service: SoftspacetechService, private fb: FormBuilder, public dialog: MatDialog, private spinner: NgxSpinnerService) { }
  carreerForms = this.fb.group({
    Name: [''],
    Mobile: [''],
    Email: [''],
    LinkedinUrl: [''],
    Qualification: ['0'],
    Place: [''],
    Descriptions: [''],
    file: [''],
    fileSource:['']
  });
  qualification: QualificationModal[] = [];
  ngOnInit(): void {
    this.service.GetQualifications().subscribe((data: any) => {
      this.qualification = data;
      
    });
  }
  get f() {
    return this.carreerForms.controls;
  }
  onFileChange(event: any) {

    if (event.target.files.length > 0) {
      this.fname = event.target.files[0];
      this.dispName = event.target.files[0].name;
      this.carreerForms.get('file')?.setValue(this.fname.name);
      this.carreerForms.patchValue({
        fileSource: this.fname
      });
    }
  }
  SubmitCarrers() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.fname);
    formData.append('Name', this.carreerForms.get('Name')?.value);
    formData.append('Email', this.carreerForms.get('Email')?.value);
    formData.append('Mobile', this.carreerForms.get('Mobile')?.value);
    formData.append('LinkedinUrl', this.carreerForms.get('LinkedinUrl')?.value);
    formData.append('QualificationID', this.carreerForms.get('Qualification')?.value);
    formData.append('Place', this.carreerForms.get('Place')?.value);
    formData.append('Description', this.carreerForms.get('Descriptions')?.value);

    this.service.PostCarrier(formData).subscribe((data: any) => {
      this.spinner.hide();
      this.validationMess = data.Message;
      this.dialog.closeAll();
      if (data.Status) {
        const dialogRef = this.dialog.open(InfoPopupComponent, {
          data: { Title: 'Carriers', Message: this.validationMess }
        });
        dialogRef.afterClosed().subscribe(e => {
          this.carreerForms.get('Name')?.setValue('');
          this.carreerForms.get('Email')?.setValue('');
          this.carreerForms.get('Mobile')?.setValue('');
          this.carreerForms.get('LinkedinUrl')?.setValue('');
          this.carreerForms.get('Qualification')?.setValue('');
          this.carreerForms.get('Place')?.setValue('');
          this.carreerForms.get('Descriptions')?.setValue('');
          this.carreerForms.get('file')?.setValue('');
          this.carreerForms.get('fileSource')?.setValue('');
          this.dispName = '';
        });
       
      }
    }, (error) => {
      this.spinner.hide();
    });
    
  }
}
export interface dialogModal {
  Title: string;
  Message: string;
}
export interface CarrierModel {
  CarrierID: number;
  Name: string;
  Mobile: string;
  Email: string;
  LinkedinUrl: string;
  QualificationID: number;
  Place: string;
  Description: string;
  Filename: string;
  file: FormData;
}
export interface QualificationModal{
  QualificationID: number;
  QualificationDesc: string;
}
