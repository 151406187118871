import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SoftspacetechService } from '../softspacetech.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, public service: SoftspacetechService) { }
  coutryCode!: any;
  
  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    const code = localStorage.getItem('CountryCode')?.toString();
    if (code == null || code == '') {
      this.service.getIpAddress().subscribe((data: any) => {
        this.service.getGEOLocation(data.ip).subscribe((x: any) => {

          localStorage.setItem('CountryCode', x.country_code2);
          this.coutryCode = x.country_code2;
        });
      });
    }
    else {
      this.coutryCode = code?.toString();
    }
  }

}
