<section id="up" class="pos-rel bg-img-cover">
  <div class="bg-video-wrap">
    <!--<video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/images/video/banner-bg-video.mp4" type="video/mp4" />
    </video>-->
    <div class="overlay">
    </div>
    <h1 class="linear-wipe">
      Services
    </h1>
  </div>
</section><!-- page head end -->

<div class="container padding-top-bottom-120 career-wraper">
  <div class="common-heading text-l">
    <h2 class="mt0">What We Do</h2>
  </div>
  <h3 class="headline-xxxs text-color-black">
    <span class="anim-slide js-scrollanim js-scrollanim-active">Services</span>
  </h3>

  <!-- flex-container start -->
  <div class="flex-container padding-top-30">
    <!-- column start -->
    <!--<p class="body-text-s text-color-black margin-top-20  tr-delay-02">
      It is a long established fact that a reader will be distracted by
      the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
      distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing
      packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites
      still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
    </p>
    <p class="body-text-s text-color-black margin-top-20  tr-delay-02">
      It is a long established fact that a reader will be distracted by
      the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
      distribution of letters, as opposed to using 'Content here, content here'.
    </p>-->
  </div><!-- flex-container end -->
  <div class="flex-container  pos-rel black-lines padding-top-30 padding-bottom-120">
    <!-- column start -->
    <div class="six-columns column-100-100 padding-top-60">
      <div class="grid-margin-box text-left js-scrollanim js-scrollanim-active">
        <div class="anim-fade">
          <span class="ic_block">
            <img src="assets/images/services//crm.png" alt="logo-image" />
          </span>
          <br>
          <h2 class="headline-xxs text-color-red margin-top-30">Ai Innovations</h2>
          <p class="body-text-s text-color-black margin-top-20">
            We assess your technological maturity and readiness for AI-driven automation and follow you through all the stages of adopting artificial intelligence
            in your business operations, from analyzing your use case to developing AI-driven software and ensuring its positive user buy-in.
            We help you move past the proof-of-concept stage and scale your AI transformation company-wide for a broader and more rewarding impact. Our artificial
            intelligence consultants will work on your long-term AI strategy to create a sustainable, cohesive AI-driven ecosystem for your business.
          </p>
        </div>
      </div><!-- grid-margin-box end -->
    </div><!-- column end -->
    <!-- column start -->
    <div class="six-columns column-100-100 padding-top-60">
      <div class="grid-margin-box text-left js-scrollanim js-scrollanim-active">
        <div class="anim-fade">
          <span class="ic_block">
            <img src="assets/images/services/recruitment.png" alt="logo-image" />
          </span>
          <br>
          <h2 class="headline-xxs text-color-red margin-top-30">Business Automation</h2>
          <p class="body-text-s text-color-black margin-top-20">
            <span *ngIf="coutryCode!='IN'">Softspace Technologies LLC</span><span *ngIf="coutryCode=='IN'">Softspace Infotech Pvt Ltd</span> develops and improves enterprise software that helps businesses of all sizes to enable automation, analytics, and collaboration. We know exactly how to leverage the cutting-edge technology solutions to generate more revenue for your and create greater value for your customers.
            We shape billions of unstructured records into informative and visualized insights which help our customers make the data-driven decisions and actions. We create end-to-end solutions designed with the needs of the asset-intensive businesses in mind to provide you with the competitive advantage.
          </p>
        </div>
      </div><!-- grid-margin-box end -->
    </div><!-- column end -->
    <!-- column start -->
    <div class="six-columns column-100-100 padding-top-60">
      <div class="grid-margin-box text-left js-scrollanim js-scrollanim-active">
        <div class="anim-fade">
          <span class="ic_block">
            <img src="assets/images/services/applicants.png" alt="logo-image" />
          </span>
          <br>
          <h2 class="headline-xxs text-color-red margin-top-30">Digital Transformation</h2>
          <p class="body-text-s text-color-black margin-top-20">
            <span *ngIf="coutryCode!='IN'">Softspace Technologies LLC</span><span *ngIf="coutryCode=='IN'">Softspace Infotech Pvt Ltd</span> helps companies make the promise of digital transformation a reality.  We work collaboratively with you to create a deep understanding of the risks and opportunities presented by new emerging technologies and think creatively about how you can use these technologies to improve business performance.
            Drawing on experts in data and analytics, technology, internal audit, business process, risk, and compliance, we tailor teams of professionals to fit the specific requirements of your transformation program.  These professionals work side-by-side with you at any or all stages of a transformation program, delivering confidence that the people, processes and technologies involved converge to create value in the future.
          </p>
        </div>
      </div><!-- grid-margin-box end -->
    </div><!-- column end -->
    <!-- column start -->
    <div class="six-columns column-100-100 padding-top-60">
      <div class="grid-margin-box text-left js-scrollanim js-scrollanim-active">
        <div class="anim-fade">
          <span class="ic_block">
            <img src="assets/images/services/user-interface.png" alt="logo-image" />
          </span>
          <br>
          <h2 class="headline-xxs text-color-red margin-top-30">Mobile App Development</h2>
          <p class="body-text-s text-color-black margin-top-20">
            Developing mobile apps is both a way of keeping up
            with the times and of offering your current and potential customers easy access to the information and
            services they seek, leading eventually to the growth of your business. Mobile app development is the best
            way to increase your online visibility than other marketing methods. <span *ngIf="coutryCode!='IN'">Softspace Technologies LLC</span><span *ngIf="coutryCode=='IN'">Softspace Infotech Pvt Ltd</span> offers next gen app development services using Google’s revolutionary framework,
            Flutter. Flutter app development services helps you get multi-platform applications with seamless
            animations, appealing UI, and excellent performance. With the use of extensible widgets of Flutter,
            our developers can build highly engaging and responsive applications for Android and iOS.
          </p>
        
        </div>
      </div><!-- grid-margin-box end -->
    </div><!-- column end -->
    <!-- column start -->
    <div class="six-columns column-100-100 padding-top-60">
      <div class="grid-margin-box text-left js-scrollanim js-scrollanim-active">
        <div class="anim-fade">
          <span class="ic_block">
            <img src="assets/images/services/web-application.png" alt="logo-image" />
          </span>
          <br>
          <h2 class="headline-xxs text-color-red margin-top-30">Web App Development</h2>
          <p class="body-text-s text-color-black margin-top-20">
            Regardless of how complex or even small your needs
            are, our dedicated in-house team of web developers and project managers will ensure that the job gets done
            with the highest level of professionalism and most cost affordably. Our expert in-house team uses cutting edge
            technologies to build custom web applications that are stunning, robust, secure, and scalable and we follow agile
            development methodology to ensure flexibility and faster delivery.
          </p>
        </div>
      </div><!-- grid-margin-box end -->
    </div><!-- column end -->
    <!-- column start -->
    <div class="six-columns column-100-100 padding-top-60">
      <div class="grid-margin-box text-left js-scrollanim js-scrollanim-active">
        <div class="anim-fade">
          <span class="ic_block">
            <img src="assets/images/services/ui-design.png" alt="logo-image" />
          </span>
          <br>
          <h2 class="headline-xxs text-color-red margin-top-30">UI/UX Designing</h2>
          <p class="body-text-s text-color-black margin-top-20">
            UX and UI are the two most important aspects of any
            design process. One drives the other and as they say UI is incomplete without UX and vice-versa. We offer
            beautiful interaction processes that are driven by the most modern technologies to offer a great interactive
            experience for your users.Intuitive designs combined with compelling user experience are what makes our apps
            stand ahead in the digital age. Seamless experience driven by international standards in collaboration with
            out-of-the-box ideas is the specialty of <span *ngIf="coutryCode!='IN'">Softspace Technologies LLC</span><span *ngIf="coutryCode=='IN'">Softspace Infotech Pvt Ltd</span> apps helping your business accomplish its goals.
          </p>
        </div>
      </div><!-- grid-margin-box end -->
    </div><!-- column end -->
  </div>
  </div>
