<section id="up" class="pos-rel bg-img-cover">
  <div class="bg-video-wrap">
    <!--<video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/images/video/banner-bg-video.mp4" type="video/mp4" />
    </video>-->
    <div class="overlay">
    </div>
    <h1 class="linear-wipe">
      Careers
    </h1>
  </div>
</section><!-- page head end -->

<div class="container padding-top-bottom-120 career-wraper">
  <div class="common-heading text-l">
    <h2 class="mt0">Let's Work Together</h2>
  </div>
  <h3 class="headline-xxxs text-color-black">
    <span class="anim-slide js-scrollanim js-scrollanim-active">Current Openings</span>
  </h3>

  <!-- flex-container start -->
  <div class="container-flex padding-top-30">
    <!-- column start -->
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel class="mat-expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title class="p-0">
            <div class="accordian-d-flex-pro">
              <h6 class="black-plus-icon headline-xxxxs text-color-black margin-top-bottom-20"><b>.Net & Angular 4+ Developer</b></h6>
              <div class="location-view-wrapper">
                <span class="location-wrap">Location : Kochi</span>
                <a class="border-btn js-pointer-large view-detail-btn">
                  <span class="border-btn__inner">View Details</span>
                  <span class="border-btn__lines-1"></span>
                  <span class="border-btn__lines-2"></span>
                </a>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="accordion__content js-accordion-content" style="display: block;">
          <p class="exp-data">Duties and Responsibilities:</p>
          <ul class="jd-list">
            <li>3 + Years of Experience in Microsoft ASP.NET / ASP.NET Core framework</li>
            <li>In-depth knowledge of Microsoft ASP.Net, C#, Ado.NET, JavaScript</li>
            <li>At least 3 years of experience in UI development and have a varied portfolio of work to show for it.</li>
            <li>Experience of developing reusable components in Angular 4.0+.</li>
            <li>Experience in developing applications using MVC architecture.</li>
            <li>
              Have knowledge in developing the multi-tenant architecture using Angular 4.0+ and knowledge of Web API with micro service.
            </li>
            <li>
              Experience in OOPS/Design Pattern and 3 tier architecture application.
            </li>
            <li>
              Knowledge of MS SQL Server.
            </li>
            <li>
              Knowledge of software development life-cycle methodology e.g., Waterfall, Agile etc.
            </li>
            <li>
              Should have hands-on experience on jQuery, JSON.
            </li>
            <li>
              Good knowledge of Java Script, Typescript, Angular 4.0+, HTML5, CSS, SCSS
            </li>
          </ul>
          <p class="exp-data">Preferred Test Tool Skills : </p>
          <div class="exp-data-c">
            <span>.Net Core</span>
            <span>MVC</span>
            <span>Web API Core</span>
            <span>SQL Server</span>
            <span>JSON</span>
            <span>jQuery</span>
            <span>Angular</span>
            <span>Microservices</span>
            <span>Agile</span>
            <span>Typescript</span>
            <span>JavaScript</span>
            <span>HTML</span>
            <span>CSS</span>
            <span>SCSS</span>
          </div>
          <p class="exp-data">Preferred Experience</p>
          <ul class="jd-list">
            <li>3-8 Years of Total Experience as .Net Web Developer.</li>
            <li>Hands on experience in development of Accounts Software or Finance Module</li>
          </ul>
          <!--<button type="button" class="btn btn-apply" (click)="applypost()">Apply Now</button>-->
          <span class="send-resume-pill"><i class="far fa-envelope"></i> Send Your Resume to hr@softspace.ae</span>
        </div>
      </mat-expansion-panel>
      <div class="hr-line"></div>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="accordian-d-flex-pro">
              <h6 class="black-plus-icon headline-xxxxs text-color-black margin-top-bottom-20"><b>UI/UX Designer : 3-7 Years</b></h6>
              <div class="location-view-wrapper">
                <span class="location-wrap">Location : Kochi</span>
                <a class="border-btn js-pointer-large view-detail-btn">
                  <span class="border-btn__inner">View Details</span>
                  <span class="border-btn__lines-1"></span>
                  <span class="border-btn__lines-2"></span>
                </a>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="accordion__content js-accordion-content" style="display: block;">
          <p class="exp-data">Responsibilities of UI-UX Designer</p>
          <ul class="jd-list">
            <li>Work with other developers to ensure that the website is functional and visually appealing</li>
            <li>Create overall concepts for the user experience within a business webpage or product, ensuring all interactions are intuitive and easy for customers</li>
            <li>Design the aesthetics to be implemented within a website or product, from the layout menus and drop-down options to colors and fonts.</li>
            <li>Build storyboards to conceptualize designs and convey project plans to clients and management</li>
            <li>Create prototypes and Wireframes for new product ideas</li>
            <li>Account for and track the human-computer interaction (HCI) element of a design</li>
            <li>Conduct testing of completed applications, websites and software to Assess user experience</li>
          </ul>
          <p class="exp-data">Qualifications for UI-UX Designer</p>
          <ul class="jd-list">
            <li>3-7 years of demonstrated experience in creating and implementing UI design for ERP applications or complex SAS model web and mobile applications.</li>
            <li>Must be proficient in UI design tool Figma and graphic designing tools such as Adobe Photoshop and Adobe Illustrator. </li>
            <li>In hand experience with video content creation and editing applications such as Adobe premier Pro and After Effects.</li>
            <li>Excellent UI design portfolio.</li>
            <li>Ability to work effectively in a collaborative environment to create top-performing interfaces.</li>
            <li>Experience creating storyboards and site mapping.</li>
            <li>Advanced problem-solving skills and the ability to optimize data for the best possible outcome.</li>
            <li>Ability to prioritize and manage multiple milestones and projects efficiently</li>
            <li>Sense of ownership and pride in your performance and its impact on company’s success.</li>
            <li>Professional written and interpersonal skills.</li>
            <li>Continued education and research into UI trends and current design strategy and technology.</li>
          </ul>
         <!-- <button type="button" class="btn btn-apply" (click)="applypost()">Apply Now</button>-->
          <span class="send-resume-pill"><i class="far fa-envelope"></i> Send Your Resume to hr@softspace.ae</span>
        </div>
      </mat-expansion-panel>
      <div class="hr-line"></div>
      <!--<mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="accordian-d-flex-pro">
              <h6 class="black-plus-icon headline-xxxxs text-color-black margin-top-bottom-20"><b>Database Developer</b></h6>
              <div class="location-view-wrapper">
                <span class="location-wrap">Location : Kochi</span>
                <a class="border-btn js-pointer-large view-detail-btn">
                  <span class="border-btn__inner">View Details</span>
                  <span class="border-btn__lines-1"></span>
                  <span class="border-btn__lines-2"></span>
                </a>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="accordion__content js-accordion-content" style="display: block;">
          <p class="exp-data">Preferred Experience : Min 6 Months</p>
          <p class="body-text-s text-color-black">

          </p>
          <ul class="jd-list">
            <li>Experience in database development and support in MS SQL Server environments.</li>
            <li>Sound knowledge in designing database,</li>
            <li>Hands on experience of Transact SQL utilizing SQL Server 2016 and above.</li>
            <li>Excellent understanding of T-SQL programming</li>
            <li>
              Develop, implement and optimize stored procedures and functions using T-SQL
            </li>
            <li>
              Analyze existing SQL queries for performance improvements.
            </li>
            <li>
              Knowledge in DBA activity like backup & recovery
            </li>
            <li>
              Extensive knowledge in SQL tuning and performance tuning
            </li>
            <li>
              Ability to work effectively independently and/or as part of a team.
            </li>
            <li>
              Great interpersonal and communication skills Roles and Responsibilities
            </li>
          </ul>
          <p class="exp-data" style="padding-top:10px;">Skills : </p>
          <div class="exp-data-c">
            <span>T-SQL</span> <span>PL/SQL</span> <span>Reporting Services</span> <span>SQL Tuning</span> <span>SSRS</span> <span>MS SQL Server</span> <span>Database Development</span>
          </div>
          <button type="button" class="btn btn-apply" (click)="applypost()">Apply Now</button>
        </div>
      </mat-expansion-panel>-->
    </mat-accordion>
  </div>
</div>

