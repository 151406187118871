<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)"
             template="<img src='assets/images/animated-icons/dot-loader.gif' />">
</ngx-spinner>
<header class="fixed-header" style="background-color:red;">
  <!-- logo start -->
  <div class="header-logo js-midnight-color js-headroom change-color"  id="container-header"  >
    <div class="hidden-box">
      
      <a href="index.html" class="header-logo__box js-pointer-large js-animsition-link">
        <img *ngIf="coutryCode!='IN'" class="header-logo__img white" src="assets/images/logo/logo-white.svg" alt="logo">
        <img *ngIf="coutryCode!='IN'" class="header-logo__img black" src="assets/images/logo/logo-black.svg" alt="logo">
        <img *ngIf="coutryCode=='IN'" class="header-logo__img white" src="assets/images/logo/logo-white-infotech.svg" alt="logo">
        <img *ngIf="coutryCode=='IN'" class="header-logo__img black" src="assets/images/logo/logo-black-infotech.svg" alt="logo">
      </a>
    </div>
  </div><!-- logo end -->
  <!-- menu-icon start -->
  <div class="menu-icon js-menu-open-close js-pointer-large js-midnight-color js-headroom">
    <div class="menu-icon__box">
      <span class="menu-icon__inner"></span>
      <span class="menu-icon__close"></span>
    </div>
  </div><!-- menu-icon end -->
  <!-- landing-nav start -->
  <nav class="landing-nav js-midnight-color change-color-nav" id="container-header-nav">
    <ul class="landing-nav__flex">
      <li class="landing-nav__btn">
        <a routerLink="/" routerLinkActive="active" class="landing-nav__inner js-smooth-scroll js-pointer-large">Home</a>
      </li>
      <li class="landing-nav__btn">
        <a routerLink="/About" routerLinkActive="active" class="landing-nav__inner js-smooth-scroll js-pointer-large">About</a>
      </li>
      <li class="landing-nav__btn">
        <a routerLink="/Services" routerLinkActive="active" class="landing-nav__inner js-smooth-scroll js-pointer-large">Services</a>
      </li>
      <li class="landing-nav__btn">
        <a routerLink="/Technology" routerLinkActive="active" class="landing-nav__inner js-smooth-scroll js-pointer-large">Technologies</a>
      </li>
      <li class="landing-nav__btn">
        <a routerLink="/Careers" routerLinkActive="active" class="landing-nav__inner js-smooth-scroll js-pointer-large">Careers</a>
      </li>
      <li class="landing-nav__btn">
        <a routerLink="/ContactUs" routerLinkActive="active" class="landing-nav__inner js-smooth-scroll js-pointer-large">Contact</a>
      </li>
    </ul>
  </nav><!-- landing-nav end -->
</header><!-- header end -->

<!-- navigation start -->
<nav class="nav-container pos-rel">
  <!-- pos-rel start -->
  <div class="pos-rel height-100perc">
    <!-- menu-box start -->
    <ul class="menu-box">
      <!-- nav-btn-box start -->
      <li class="nav-btn-box">
        <a href="#up" class="nav-btn large js-pointer-large js-smooth-scroll js-landing-close">
          <span class="nav-btn__inner" data-text="Home">Home</span>
        </a>
      </li><!-- nav-btn-box end -->
      <!-- nav-btn-box start -->
      <li class="nav-btn-box">
        <a routerLink="/About" routerLinkActive="active" class="nav-btn large js-pointer-large js-smooth-scroll js-landing-close">
          <span class="nav-btn__inner" data-text="About">About</span>
        </a>
      </li><!-- nav-btn-box end -->
      <!-- nav-btn-box start -->
      <li class="nav-btn-box">
        <a routerLink="/Services" routerLinkActive="active" class="nav-btn large js-pointer-large js-smooth-scroll js-landing-close">
          <span class="nav-btn__inner" data-text="Services">Services</span>
        </a>
      </li><!-- nav-btn-box end -->
      <!-- nav-btn-box start -->
      <li class="nav-btn-box">
        <a routerLink="/Technology" routerLinkActive="active" class="nav-btn large js-pointer-large js-smooth-scroll js-landing-close">
          <span class="nav-btn__inner" data-text="Technology">Technology</span>
        </a>
      </li><!-- nav-btn-box end -->
      <!-- nav-btn-box start -->
      <li class="nav-btn-box">
        <a routerLink="/Careers" routerLinkActive="active" class="nav-btn large js-pointer-large js-smooth-scroll js-landing-close">
          <span class="nav-btn__inner" data-text="Careers">Careers</span>
        </a>
      </li><!-- nav-btn-box end -->
      <!-- nav-btn-box start -->
      <li class="nav-btn-box">
        <a routerLink="/ContactUs" routerLinkActive="active" class="nav-btn large js-pointer-large js-smooth-scroll js-landing-close">
          <span class="nav-btn__inner" data-text="Contact">Contact</span>
        </a>
      </li><!-- nav-btn-box end -->
    </ul><!-- menu-box end -->
  </div><!-- pos-rel end -->
</nav><!-- navigation end -->
<main class="js-animsition-overlay" data-animsition-overlay="true">
  <router-outlet></router-outlet>
</main><!-- main end -->
<app-footer></app-footer>

