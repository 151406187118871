import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { CarrierModel } from './carrierform/carrierform.component';
import { EnquiryModel } from './contact/contact.component';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class SoftspacetechService {
 // serviceUrl: string = 'https://localhost:44330/api/General/';
 // serviceUrl: string = 'http://194.233.75.55:5002/api/General/';
  /*serviceUrl: string = 'https://194.233.75.55/api/General/'*/
  serviceUrl: string = 'https://194.233.75.55/SoftspaceOnline/api/General/';
  constructor(private httpclient: HttpClient) { }

  GetQualifications() {
    return this.httpclient.post<any>(this.serviceUrl + 'GetQualifications', null, httpOptions);
  }
  PostCarrier(data: any) {
    return this.httpclient.post<any>(this.serviceUrl + 'PostCarrier', data);
  }
  PostEnquiry(data: EnquiryModel) {
    return this.httpclient.post<any>(this.serviceUrl + 'PostEnquiry', data, httpOptions);
  }
  getIpAddress() {
    return this.httpclient
      .get('https://api.ipify.org/?format=json')
      .pipe(
        catchError(this.handleError)
      );
  }
  getGEOLocation(ip: string) {
    // Update your api key to get from https://ipgeolocation.io
    let url = "https://api.ipgeolocation.io/ipgeo?apiKey=31ddb219a3454b3f92d131d553e8b65a&ip=" + ip;
    return this.httpclient
      .get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
