<section id="up" class="pos-rel bg-img-cover">
  <div class="bg-video-wrap">
    <!--<video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/images/video/banner-bg-video.mp4" type="video/mp4" />
    </video>-->
    <div class="overlay">
    </div>
    <h1 class="linear-wipe">
      Technologies
    </h1>
  </div>
</section><!-- page head end -->


<div class="container padding-top-bottom-120 tech-wrapper">
  <div class="common-heading text-l">
    <h2 class="mt0">What We Use</h2>
  </div>
  <h3 class="headline-xxxs text-color-black">
    <span class="anim-slide js-scrollanim js-scrollanim-active">Technologies</span>
  </h3>

  <!-- flex-container start -->
  <div class="flex-container padding-top-30">

    <!-- column start -->
    <div class="four-columns column-100-100 padding-top-30">
      <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active">
        <span class="subhead-xxl text-color-red d-block hidden-box">
          <span class="ic_block">
            <img src="assets/images/technologies/angular.png" alt="logo-image" />
          </span>
        </span>
        <h2 class="headline-xxs text-color-red margin-top-30">
          Angular 11
        </h2>
        <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-delay-02">
          Angular 11, the Google-developed web framework, focuses on enhancing the developer experience. It aims to simplify the whole development procedure. Angular turns the templates into code
          that's highly optimized for today's JavaScript virtual machines, giving us all the benefits of hand-written code with the productivity of a framework.
          Angular apps load quickly with the new Component Router, which delivers automatic code-splitting, so users only load code required to render the view
          they request. With this latest feature, apps are made faster by speeding up the first contentful paint. During compile time, the Angular CLI will download,
          and inline fonts being used and linked in the application. Automatic font in lining will be enabled by default in apps with Angular 11. Applications will
          need to be updated to take advantage of this.
        </p>

      </div>
    </div><!-- column end -->
    <!-- column start -->
    <div class="four-columns column-100-100 padding-top-30">
      <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active">
        <span class="subhead-xxl text-color-red d-block hidden-box">
          <span class="ic_block">
            <img src="assets/images/technologies/dongolok.png" alt="logo-image" />
          </span>
        </span>
        <h2 class="headline-xxs text-color-red margin-top-30">
          .netCore
        </h2>
        <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-delay-02">
          The .NET Core is the evolution of Microsoft’s .NET technology into a modular, cross platform, open source, and cloud ready platform,
          which runs on Windows, Mac, Android, IoT and Linux. Having the same codebase for multiple operating systems reduces costs by eliminating
          the hassle of having to maintain, develop, track, fix and test the same bugs in at least two places. The lightweight .NET Core framework
          provides much faster performance over some of the competing technologies, notably Node.js and GO. As coding is less, the smaller number of
          hours are required to create an application which makes ASP.NET Core much more cost-effective. ASP.NET Core can easily help in developing
          great and robust web applications. ASP.NET Core has the power to design highly dynamic web apps for a successful business.
        </p>
      </div>
    </div><!-- column end -->
    <!-- column start -->
    <div class="four-columns column-100-100 padding-top-30">
      <div class="column-r-margin-40-999 js-scrollanim js-scrollanim-active">
        <span class="subhead-xxl text-color-red d-block hidden-box">
          <span class="ic_block">
            <img src="assets/images/technologies/chayhana.png" alt="logo-image" />
          </span>
        </span>
        <h2 class="headline-xxs text-color-red margin-top-30">
          Flutter
        </h2>
        <p class="body-text-s text-color-black margin-top-20 anim-text-reveal tr-delay-02">
          Flutter is Google’s software development kit for building iOS and Android apps. It’s a cross-platform technology. It is designed to allow code reuse
          across operating systems such as iOS and Android, while also allowing applications to interface directly with underlying platform services. The
          goal is to enable developers to deliver high-performance apps that feel natural on different platforms, embracing differences where they exist
          while sharing as much code as possible. During development, Flutter apps run in a VM that offers stateful hot reload of changes without needing
          a full recompile. For release, Flutter apps are compiled directly to machine code, whether Intel x64 or ARM instructions, or to JavaScript if
          targeting the web. It has a thriving ecosystem of third-party packages that supplement
          the core library functionality.
        </p>

      </div>
    </div><!-- column end -->
  </div><!-- flex-container end -->
</div>
