import { Component } from '@angular/core';
import { SoftspacetechService } from './softspacetech.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SoftspaceApp';
  ipaddr: string = '';
  coutryCode!: any;
  constructor(public service: SoftspacetechService) { }
  ngOnInit(): void {
    const key = localStorage.getItem('CountryCode');
    if (key == null || key == '') {
      this.service.getIpAddress().subscribe((data: any) => {
        this.ipaddr = data.ip;
        this.service.getGEOLocation(this.ipaddr).subscribe((x: any) => {

          localStorage.setItem('CountryCode', x.country_code2);
          this.coutryCode = x.country_code2;

        });
      });
    } else {
      this.coutryCode = key?.toString();
    }
  }
}
