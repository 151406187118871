<section id="up" class="pos-rel bg-img-cover">
  <div class="bg-video-wrap">
    <!--<video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/images/video/banner-bg-video.mp4" type="video/mp4" />
    </video>-->
    <div class="overlay">
    </div>
    <h1 class="linear-wipe">
      About Us
    </h1>
  </div>
</section><!-- page head end -->

<div class="container padding-top-bottom-120 career-wraper">
  <div class="common-heading text-l">
    <h2 class="mt0">Who We Are</h2>
  </div>
  <h3 class="headline-xxxs text-color-black">
    <span class="anim-slide js-scrollanim js-scrollanim-active">About Us</span>
  </h3>
  <div class="flex-container padding-top-30">
    <h2 class="headline-xxs text-color-red margin-top-30">Company Overview</h2>
    <p class="body-text-s text-color-black margin-top-20 tr-delay-02">
      Softspace Infotech Pvt Ltd is an Indian Subsidiary  of Softspace Technologies LLC, Dubai. We started with the idea to create world class solutions which satisfy the demand of digital transformation by
      providing our Expertization and skilled services that foster competitiveness and innovation. We are passionate about creating the technologies with
      the new paces of Artificial Intelligence and Internet of Things. Our company achieves the goals under the leadership and spectacular visions of our
      chairman Mr. Ahmed Al Suwaidi.
    </p>
    <p class="body-text-s text-color-black margin-top-20 tr-delay-02">
      We believe that the future is digital. We also believe that without embracing new technology, businesses will strive in chaos.
    </p>
  </div>
  <!-- flex-container start -->
  <div class="flex-container padding-top-30">
    <div class="six-columns column-100-100">
      <div class="column-r-margin-40-999">
        <h2 class="headline-xxs text-color-red margin-top-30">Vision</h2>
        <p class="body-text-s text-color-black margin-top-20  tr-delay-02">
          Our Vision is cultivating creativity by encouraging it and harnessing fresh ideas, perspectives,
          techniques, and sources of inspiration that make us more effective problem-solvers.
        </p>
      </div>
    </div>
    <div class="six-columns column-100-100">
      <div class="column-r-margin-40-999">
        <h2 class="headline-xxs text-color-red margin-top-30">Mission</h2>
        <p class="body-text-s text-color-black margin-top-20  tr-delay-02">
          Our mission is to create data-driven solutions designed especially for the needs of each of our new customer leveraging the best practices we have
          learned working with other business domains.
        </p>
      </div>
    </div>
    <!-- column start -->
  </div><!-- flex-container end -->
</div>
